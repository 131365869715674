import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/kernel-v2/kernel-v2/src/modules/layouts/default_layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Process = makeShortcode("Process");
const InfoBlock = makeShortcode("InfoBlock");
const Box = makeShortcode("Box");
const Aligner = makeShortcode("Aligner");
const Button = makeShortcode("Button");
const Flash = makeShortcode("Flash");
const Card = makeShortcode("Card");
const Column = makeShortcode("Column");
const Image = makeShortcode("Image");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "-what-is-amazon",
      "style": {
        "position": "relative"
      }
    }}>{`🌲 What is Amazon?`}<a parentName="h1" {...{
        "href": "#-what-is-amazon",
        "aria-label": " what is amazon permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h1>
    <p>{`Now that we have explored the deep links between incentives and the kinds of stories we listen to and relate–the narratives we find to be truly charming–we can outline the argument we'll be making in the rest of this module. We're not interested in the latest DeFi protocol, or specific curves, curation markets, coin offerings and all the other crazes.`}</p>
    <p>{`We're interested in the `}<a parentName="p" {...{
        "href": "/learn/module-1/dreamers"
      }}>{`shared dreams`}</a>{` instantiated by networks of `}<a parentName="p" {...{
        "href": "/learn/module-4/the-garden/#shared-realities"
      }}>{`timestamp servers that challenge regimented political routines`}</a>{`. In fact, such networks create an entire universe of distributed `}<a parentName="p" {...{
        "href": "/learn/module-0/trust/"
      }}>{`trust`}</a>{` in which we can implement `}<a parentName="p" {...{
        "href": "/learn/module-0/money-language/#open-protocols-and-a-network-of-value"
      }}>{`instant settlement and transfer of ownership`}</a>{`, without requiring intermediaries.`}</p>
    <p>{`We haven't even scratched the surface of possible mechanisms in such a space. It is that vast. Therefore, we'll take a step back and look at:`}</p>
    <Process mdxType="Process">
      <p>{`the most successful mechanisms designed at the advent of online commerce  `}</p>
      <p>{`the incredible power of narrative and how it is used to both shape and challenge the incentive structures which support power  `}</p>
      <p>{`the worlds designed for computer games illuminate what prosocial mechanism design looks like and how it can be used to encourage better relationships and guard against toxicity and loneliness in our social systems`}</p>
    </Process>
    <p>{`Designing algorithms is nothing new. So, before we get all New Age crypto-economic, let's turn to two of the most successful mechanisms designed in modern history: one business from just before the internet, and one which was birthed as a result of it. This week, we're traveling deep into the `}<a parentName="p" {...{
        "href": "/learn/module-4/consensus/#how-does-this-fit-into-kernel"
      }}>{`fractal forests`}</a>{`, and there are miles to go before we sleep.`}</p>
    <InfoBlock mdxType="InfoBlock">
      <Box mdxType="Box">
        <p><strong parentName="p">{`Unbounded Search`}</strong></p>
        <p><img parentName="p" {...{
            "src": "/images/mod5-img/amazon.jpg",
            "alt": "Unbounded Search"
          }}></img></p>
        <Aligner center mdxType="Aligner">
          <Button to="https://zackkanter.com/2019/03/13/what-is-amazon/" mdxType="Button">
    Read
          </Button>
        </Aligner>
      </Box>
    </InfoBlock>
    <h3 {...{
      "id": "how-does-this-fit-into-kernel",
      "style": {
        "position": "relative"
      }
    }}>{`How does this fit into Kernel?`}<a parentName="h3" {...{
        "href": "#how-does-this-fit-into-kernel",
        "aria-label": "how does this fit into kernel permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h3>
    <p>{`This brief is based on an essay by Zack Kanter which is about the history of Amazon and how Jeff Bezos turned it into such a successful internet platform. Zack shows clearly how critical incentives are, especially in the age of internet businesses and the unbounded digital spaces over which they operate. Before writing effective economic code owned by no-one which benefits everyone, we would do well to understand the most successful mechanisms designed so far, so that we can mimic their best parts and understand where they can actually be improved. Seeing how algorithms, platforms, and incentives operate together will allow us to think more carefully about how to program our societies sustainably.`}</p>
    <h2 {...{
      "id": "brief",
      "style": {
        "position": "relative"
      }
    }}>{`Brief`}<a parentName="h2" {...{
        "href": "#brief",
        "aria-label": "brief permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h2>
    <p>{`Zack Kanter explores what Amazon `}<em parentName="p">{`actually`}</em>{` is by beginning with the business it first modeled
itself on: Walmart. In order to understand the most successful commercial mechanism produced on
and by the internet, we need to understand its retail analogue.`}</p>
    <h3 {...{
      "id": "algorithms-to-sell-by",
      "style": {
        "position": "relative"
      }
    }}>{`Algorithms to sell by`}<a parentName="h3" {...{
        "href": "#algorithms-to-sell-by",
        "aria-label": "algorithms to sell by permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h3>
    <blockquote>
      <p parentName="blockquote">{`"Few people outside of Walmart realize Walmart’s historical scope of innovation. It built the
largest private satellite communications network, enabling unprecedented coordination at
enormous scale. Computerized point of sale systems, a massive trucking fleet to enable
best-in-class logistics, innovations in EDI, the Sam’s Club format."`}</p>
    </blockquote>
    <p>{`That is, `}<strong parentName="p">{`Walmart can be thought of as a very simple formula`}</strong>{` which optimizes for the
selection, pricing, and inventory of goods in a local store of some limited square footage.`}</p>
    <blockquote>
      <p parentName="blockquote">{`"Sam Walton designed the Walmart algorithm: that is, a) “a wide assortment of good quality
merchandise”, b) offered “at the lowest possible prices,” c) backed by “guaranteed satisfaction”
and “friendly, knowledgeable service,” d) available during “convenient hours” with “free parking”
and “a pleasant shopping experience,” e) all within the largest, most convenient possible store
size and location permitted by local economics."`}</p>
    </blockquote>
    <p>{`Walmart became the best in the world at using retail square footage as effectively as possible,
stocking it with good quality merchandise at the lowest possible prices, and maintaining
sufficient inventory to satisfy the resulting customer demand.`}</p>
    <blockquote>
      <p parentName="blockquote">{`"Walmart can be thought of as a `}<strong parentName="p">{`bounded search for the optimal selection`}</strong>{`, inventory, and
pricing of SKUs that a `}<strong parentName="p">{`local market`}</strong>{` could support. It was bound, or constrained, by the
characteristics of the local economy, and so each Walmart location was a direct reflection of
the local market dynamics `}{`[...]`}{` Higher-level managers were responsible for estimating the
optimal size and location of the building itself, and for choosing the best associates to manage
it. Each level of management had to manage their own level of the algorithm `}{`[...]`}{` This worked
incredibly well until 1994 when – almost overnight – the algorithm that Walmart had methodically
honed over the past three decades started to quietly work against it."`}</p>
    </blockquote>
    <Flash mdxType="Flash">
      <Card mdxType="Card">
        <p>{`Prompt: Walmart can be thought of as a `}{`_`}{`_`}{`_`}{`_`}{`_`}{`_`}{` `}{`_`}{`_`}{`_`}{`_`}{`_`}{`_`}{`_`}{` for the selection, pricing, and inventory of goods in a local store.`}</p>
        <p>{`simple formula.`}</p>
      </Card>
    </Flash>
    <h3 {...{
      "id": "fractal-forests",
      "style": {
        "position": "relative"
      }
    }}>{`Fractal forests`}<a parentName="h3" {...{
        "href": "#fractal-forests",
        "aria-label": "fractal forests permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h3>
    <blockquote>
      <p parentName="blockquote">{`"Jeff Bezos had a big realization in 1994: the world of retail had, up until then, been a
world where the most important thing was optimizing limited shelf space in service of satisfying
the customer – but that world was about to change drastically. The advent of the internet – of
online shopping – meant that an `}<strong parentName="p">{`online retailer had infinite shelf space`}</strong>{`."`}</p>
    </blockquote>
    <p>{`This is the reason we go back and look at our roots - it leads to fascinating questions about
what fundamental shifts in media environments are `}<em parentName="p">{`actually`}</em>{` all about. It's often non-intuitive:
who really cares about shelf space? Well, Bezos did, and he's done fairly well as a result. Can
we find the next ordinary parameter which has been blown away by networked protocols for value and
write algorithms which are premised on the idea that wealth means having enough to share?`}</p>
    <blockquote>
      <p parentName="blockquote">{`💡 Bezos built an unbounded Walmart. What does an unbounded state look like?`}</p>
    </blockquote>
    <Flash mdxType="Flash">
      <Card mdxType="Card">
        <p>{`Prompt: What is the critical difference between an online retailer and their physical competitors?`}</p>
        <p>{`Infinite shelf space.`}</p>
      </Card>
    </Flash>
    <blockquote>
      <p parentName="blockquote">{`"In this world of infinite shelf space, it wasn’t the quality of the selection that mattered –
it was pure quantity `}{`[...]`}{` Amazon's algorithm – borrowed and modified from Walmart – was simple:
a) a vast selection, b) delivered fast, c) at the lowest possible prices, d) backed by guaranteed
satisfaction `}{`[...]`}{` They correctly hypothesized that because vendor selection was not important
in the world of infinite shelf space, Amazon itself – or, more accurately, its vendor onboarding
process – would be the bottleneck to growth `}{`[...]`}{` In its effort to remove this bottleneck,
Amazon opened its website to third party sellers."`}</p>
    </blockquote>
    <h3 {...{
      "id": "open-the-gates",
      "style": {
        "position": "relative"
      }
    }}>{`Open the gates!`}<a parentName="h3" {...{
        "href": "#open-the-gates",
        "aria-label": "open the gates permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h3>
    <p>{`Amazon has never become as good as Walmart at negotiating prices, but they don't `}<em parentName="p">{`need to`}</em>{`, because
even if they 'lose' the price negotiation to a third party, they still gain a data point; the
customer stays on their site; and they charge a 12-15% commission. All they did at first was
systematically remove friction for sellers, doing small things like eliminating the UPC code
requirement that would serve as a barrier for newer, less established sellers.`}</p>
    <blockquote>
      <p parentName="blockquote">{`"To make sense of what started to happen after Amazon rolled out Marketplace, you have to
understand that `}<a parentName="p" {...{
          "href": "/learn/module-1/dreamers/#an-infinite-stock-of-games"
        }}>{`things get really weird`}</a>{` when you run an unbounded search at internet-scale. When you remove “normal” constraints imposed by the physical world, the scale can get so massive that all of the normal approaches start to break down."`}</p>
    </blockquote>
    <p>{`Walmart had solved these almost-impossible problems for a business of its scale, and engineered a genuine marvel of the modern world focused on thinking small to stamp out any possible inefficiency and optimize its shelf space. Amazon found a way around the problems of price negotiation or just-in-time stock delivery with limited shelf space: make as many shelves as necessary and let vendors list their goods at whatever price they like. In the digital world, the idea of endless shelves is not absurd, and bidding vendors down to the best price for your customers is not necessary: you just need to make sure customers can reliably discover the best products at the lowest prices. `}</p>
    <p>{`The two big problems Amazon has to solve are therefore `}<strong parentName="p">{`search`}</strong>{` and `}<strong parentName="p">{`discovery`}</strong>{`. `}</p>
    <p>{`Searching efficiently over endless shelves requires enormous computing power and data storage. Engineers had to wait weeks to get servers provisioned. So, Amazon began to build a platform that would allow its software engineers to provision on-demand resources immediately. Moreover, they couldn't develop features on the website fast enough to take advantage of all the merchandising opportunities.`}</p>
    <blockquote>
      <p parentName="blockquote">{`"In a radical move, the platform – Amazon’s own technological infrastructure – would be made available to external developers, too. It would be called Amazon Web Services `}{`[...]`}{` In a similarly radical move, Bezos decided to expose Amazon’s entire product catalog via an API so that any software developer, internal or external, could access Amazon’s catalog and use the SKU data, within reason, in any way they saw fit."`}</p>
    </blockquote>
    <p>{`This should remind you of Andreas Antonopoulos' point about how `}<a parentName="p" {...{
        "href": "/learn/module-0/money-language/#open-protocols-and-a-network-of-value"
      }}>{`we're in the midst of a move from closed organizations, to platforms accessible through APIs, to open protocols`}</a>{`. Platforms spring up as a necessity borne from unbound searches running at internet scale.`}</p>
    <blockquote>
      <p parentName="blockquote">{`"Circa 2002, we start to see the emergence of a pattern: 1) Amazon had encountered a
bottleneck to growth, 2) it had determined that some internal process or resource was the
bottleneck, 3) it had realized that it could not possibly develop and deploy enough resources
internally to remove that bottleneck, so 4) it instead removed the bottleneck by building an
interface to allow the broader market to solve it en masse, so that they can `}<strong parentName="p">{`become a customer of their own open platform`}</strong>{`. This exact pattern was repeated with vendor selection (Amazon Marketplace), technology infrastructure (Amazon Web Services, or AWS), and merchandising (Amazon’s Catalog API).`}</p>
    </blockquote>
    <Flash mdxType="Flash">
      <Card mdxType="Card">
        <p>{`Prompt: How has Amazon solved internal bottlenecks while running an unbounded search at internet-scale with infinite shelf space?`}</p>
        <p>{`(By building and becoming a customer of their own) open platforms.`}</p>
      </Card>
    </Flash>
    <h3 {...{
      "id": "platforms-are-the-algorithm",
      "style": {
        "position": "relative"
      }
    }}>{`Platforms are the algorithm`}<a parentName="h3" {...{
        "href": "#platforms-are-the-algorithm",
        "aria-label": "platforms are the algorithm permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h3>
    <p>{`Walmart built Retail Link to share its inventory levels and internal projections with vendors.
However, there is a crucial difference: lock-in. Because the suppliers who want to sell through
Walmart must use Retail Link, there's no external pressure to keep improving the service. `}</p>
    <blockquote>
      <p parentName="blockquote">{`"When a service has captive customers, it will degrade compared to market alternatives."`}</p>
    </blockquote>
    <p>{`Amazon faced this problem with AWS, but solved it by opening up the platform to all customers
and `}<em parentName="p">{`itself becoming a customer of AWS`}</em>{`. It thus solved its own technological bottleneck without
falling into the trap of vertical integration. `}</p>
    <blockquote>
      <p parentName="blockquote">{`"In 2002, Jeff Bezos had the most important insight he would ever have: in the world of infinite shelf space – and platforms to fill them – the limiting reagent for Amazon’s growth would not be its website traffic, or its ability to fulfil orders, or the number of SKUs available to sell; `}<strong parentName="p">{`it would be its own bureaucracy.`}</strong></p>
    </blockquote>
    <blockquote>
      <p parentName="blockquote">{`"Bezos issued an edict: `}<em parentName="p">{`1) All teams will henceforth expose their data and functionality
through interfaces, 2) teams must communicate with each other through these interfaces, 3) all
interfaces, without exception, must be designed from the ground up to be exposed to developers
in the outside world, and 4) anyone who doesn’t do this will be fired.`}</em>{`"`}</p>
    </blockquote>
    <blockquote>
      <p parentName="blockquote">{`💡 Platforms became Amazon’s answer to every growth obstacle it encountered. Platforms became
part of the algorithm.`}</p>
    </blockquote>
    <blockquote>
      <p parentName="blockquote">{`"Bezos designed Amazon’s algorithm. His first stroke of genius was in making it unbounded; his
second – the masterstroke – was devising a solution to the bureaucratic complexity that would
have otherwise caused it to implode. Instead of being a bureaucratic liability, Amazon’s sprawl
would become a massive surface area of customer contact from which Amazon could spawn dozens of
revenue streams."`}</p>
    </blockquote>
    <h3 {...{
      "id": "unsolved-advertising",
      "style": {
        "position": "relative"
      }
    }}>{`Unsolved: advertising`}<a parentName="h3" {...{
        "href": "#unsolved-advertising",
        "aria-label": "unsolved advertising permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h3>
    <p>{`With as much shelf space as necessary, the next big problem is `}<em parentName="p">{`discoverability`}</em>{`. Typically, this was
answered with a platform: Amazon Advertising. "Sponsored Products" go at the top of search,
help sellers get in front of customers, help customers find the latest and greatest goods, and
generate pure revenue for Amazon - $8bn of it.`}</p>
    <p>{`However, this is not actually good for customers - it's only good for sellers who are good at
advertising, favoring products with the highest gross margin, not those which are necessarily
best for the customer. People can't readily distinguish between organic search and sponsored
products and so are unlikely to be buying the optimal product. Large, sponsored listings drag
the average quality of products sold closer to mediocrity, and further from greatness.`}</p>
    <Flash mdxType="Flash">
      <Card mdxType="Card">
        <p>{`Prompt: What is the one systemic problem Amazon cannot solve with open platforms?`}</p>
        <p>{`Advertising (or discoverability).`}</p>
      </Card>
    </Flash>
    <p>{`Zack suggests a lifespan on product reviews as a possible solution, though this seems like a
temporary patch on a systemic issue. Moreover, he highlights how Amazon is likely addicted to
that $8bn in revenue, which sponsors over 1/3 of its R&D budget.`}</p>
    <blockquote>
      <p parentName="blockquote">{`"This is an absolutely devastating misstep for Amazon’s retail business. This isn’t “just”
search results; search results are the entire driver of Amazon’s retail engine. Remember that
in the world of infinite shelf space, the ranking algorithm is practically the entire
merchandising strategy. Organic, customer-centric product rankings – the strategy that brought
Amazon to $250 billion in retail revenue – has been permanently distorted `}{`[...]`}</p>
    </blockquote>
    <blockquote>
      <p parentName="blockquote">{`This misstep is symptomatic of the weirdness that eventually happens when an unbound search
runs across such a massive problem space `}{`[...]`}{` As soon as a system’s rules are understood, it
will be gamed according to the rules that have been created."`}</p>
    </blockquote>
    <p>{`While Amazon's catalog continues to grow day by day, the number of top slots does not. Growing
competition puts enormous pressure on the whole system and incentivizes bad-actor tactics. `}</p>
    <h3 {...{
      "id": "the-opportunity",
      "style": {
        "position": "relative"
      }
    }}>{`The opportunity`}<a parentName="h3" {...{
        "href": "#the-opportunity",
        "aria-label": "the opportunity permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h3>
    <blockquote>
      <p parentName="blockquote">{`"Amazon has not yet figured out how to extend its internal incentive structure – the incentive
structure that has been so successful in keeping the company customer-obsessed – to its external
platform participants: the sellers `}{`[...]`}{` The point is that two years ago, it was hard to think
of even theoretical ways that Amazon could have been caught; today, there is an opening – a real
one, of meaningful size."`}</p>
    </blockquote>
    <p>{`Of course, it seems unlikely that anyone other than Amazon will `}<a parentName="p" {...{
        "href": "/learn/module-2/engineering/#new-conversations"
      }}>{`disrupt`}</a>{` Amazon given the open, platform-driven algorithm Bezos stumbled upon and formalized around 2002. However, it's critical to note how powerful incentives really are - indeed, so powerful that they present the only meaningful means of competing with a business the size of Amazon. `}</p>
    <blockquote>
      <p parentName="blockquote">{`"So, what is Amazon? It started as an unbound Walmart, an algorithm for running an unbound
search for global optima in the world of physical products. It became a platform for adapting
that algorithm to any opportunity for customer-centric value creation that it encountered. If it
devises a way to keep its incentive structures intact as it exposes itself through its
ever-expanding external interfaces, it – or its various split-off subsidiaries – will dominate
the economy for a generation. And if not, it’ll be just another company that seemed unstoppable
until it wasn’t."`}</p>
    </blockquote>
    <Flash mdxType="Flash">
      <Card mdxType="Card">
        <p>{`Prompt: What powerful feature of Web 3 presents the only meaningful way to compete with a business the size of Amazon?`}</p>
        <p>{`(Programmable) incentives.`}</p>
      </Card>
    </Flash>
    <h3 {...{
      "id": "further-references",
      "style": {
        "position": "relative"
      }
    }}>{`Further references`}<a parentName="h3" {...{
        "href": "#further-references",
        "aria-label": "further references permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h3>
    <p>{`The first section's title is a riff on a great book which you should read if you're interested
in algorithms. If specifically internet age algorithms are more your thing, take a look over the
latest TikTok controversy from a slightly different perspective.`}</p>
    <Column sx={{
      gap: "10%"
    }} mdxType="Column">
      <Box mdxType="Box">
        <p><strong parentName="p">{`Algorithms to Live By`}</strong></p>
        <Aligner center mdxType="Aligner">
    <Image alt="Algorithms to Live By" src="/images/mod5-img/algo-live.jpg" mdxType="Image" />
        </Aligner>
        <Aligner center mdxType="Aligner">
          <Button to="https://www.goodreads.com/book/show/25666050-algorithms-to-live-by" mdxType="Button">
    Read
          </Button>
        </Aligner>
      </Box>
      <Box mdxType="Box">
        <p><strong parentName="p">{`TikTok`}</strong></p>
        <p><img parentName="p" {...{
            "src": "/images/mod5-img/tiktok.png",
            "alt": "TikTok"
          }}></img></p>
        <Aligner center mdxType="Aligner">
          <Button to="https://stratechery.com/2020/the-tiktok-war/" mdxType="Button">
    Ruminate
          </Button>
        </Aligner>
      </Box>
    </Column>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      